<template>
  <draggable v-model="model" group="people">
    <div v-for="(layer, index) in model" :key="index" class="row">
      <el-checkbox class="checkbox" v-model="layer.isVisible" disabled></el-checkbox>
      <span style="flex: 1;min-width: 0;">
        <LayersIcon v-if="layer.properties.iconStyleTypeId != null" :type="layer.properties.iconStyleTypeId" :properties="layer.properties.style[`${layer.properties.iconStyleTypeId}`]"  style="width: 15px; height: 15px; display: inline-block;"/>
        <span class="label" style="margin-left: 2px; display: inline-block;">{{ layer.name }}</span>
      </span>
      <span class="index">{{ index + 1}}</span>
    </div>
  </draggable>
</template>

<script>
// sort((a, b) => (a.properties.zIndex > b.properties.zIndex) ? 1 : -1)
import draggable from 'vuedraggable'
import { NodeLayerType } from '@/components/InterfaceEditor/components/basic/Map/NodeLayerType'
import LayersIcon from '@/core/infrastructure/components/LayerIcon'
export default {
  components: {
    draggable,
    LayersIcon
  },
  name: 'layers-sort',
  props: {
    layers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      model: []
    }
  },
  methods: {
    createList (array) {
      array.forEach((item) => {
        if (item.type === NodeLayerType.LAYER) {
          this.model.push(item)
        } else if (item.children && item.children.length > 0) {
          this.createList(item.children)
        }
      })
    }
  },
  mounted () {
    this.createList(this.layers)
    this.model = this.model.sort((a, b) => (a.properties.zIndex < b.properties.zIndex) ? 1 : -1)
  },
  watch: {
    model: {
      handler (array) {
        array.forEach((item, index) => {
          this.$set(item.properties, 'zIndex', 1000 - index)
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.row {
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;
}
.checkbox {
  margin-right: 8px;
}
.row:hover {
  background-color: rgb(245 247 250);
}
.label {
  color: #2D3239;
  white-space: nowrap;
  width: calc(100% - 40px);
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.dot {
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
  vertical-align: text-top;
}
.index {
  position: absolute;
  right: 10px;
  color: #c0c4cc;
}
</style>
